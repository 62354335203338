import React from 'react'
import {Link, Redirect} from 'react-router-dom'
import {Helmet} from "react-helmet"
import ReactWOW from 'react-wow';
import InputMask from 'react-input-mask'
import {isMobile, isIOS, isAndroid, isTablet} from 'react-device-detect'
import CustomScroll from 'react-custom-scroll';
import Slider from "react-slick"
import Modals from './Modals'
import Header from "./Header";

const API_URL = process.env.REACT_APP_API;

let sl_set = {};

export default class Profile extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isReady: false,
            name: '',
            surname: '',
            patronymic: '',
            phone: '',
            nameError: false,
            surnameError: false,
            patronymicError: false,
            phoneError: false,
            nameErrorText: 'Некорректное имя',
            surnameErrorText: 'Некорректная фамилия',
            patronymicErrorText: 'Некорректное отчество',
            phoneErrorText: 'Некорректный телефон',
            name_ds: false,
            surname_ds: false,
            phone_ds: false,
            patronymic_ds: false,
        }
    }

    findGetParameter = (parameterName) =>{
        let result = null,
            tmp = [];
        let items = window.location.search.substr(1).split("&");
        for (let index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        }
        return result;
    }

    componentDidMount() {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);

        let urlParams = window.location.search.substr(1);
        if(urlParams !== '' && urlParams !== undefined && urlParams !== null){
            let utm_source = this.findGetParameter('utm_source');

            if(utm_source !== null && utm_source !== '' && utm_source !== undefined){
                window.location.href = '/game?utm_source=' + utm_source;
            }
        }

        if(localStorage.getItem('auth_key')){
            this.getProfile();
        }
    }

    componentWillMount(){
        sl_set = {
            infinite: true,
            dots: false,
            arrows: false,
            fade: true,
            waitForAnimate: false,
            /*autoplaySpeed: 3000,
            autoplay: true,*/
            pauseOnHover: false,
            beforeChange: (oldIndex, newIndex) => {
                this.setState({
                    cur_pr: (newIndex + 1),
                    sliding: true
                })
            },
            afterChange: (newIndex) => {
                this.setState({
                    sliding: false
                })
            }
        }
    }

    getProfile = async () => {
        await fetch(API_URL + 'getProfile', {
            method: "GET",
            headers:{
                'X-Auth-Token': localStorage.getItem('auth_key'),
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                let data = response.data;

                this.setState({
                    name: data.profile.name ?? '',
                    surname: data.profile.surname ?? '',
                    patronymic: data.profile.patronymic ?? '',
                    phone: data.profile.phone ?? '',
                });


                this.setState({
                    name_ds: (data.profile.name ? true : false),
                    surname_ds: (data.profile.surname ? true : false),
                    patronymic_ds: (data.profile.patronymic ? true : false),
                    phone_ds: (data.profile.phone ? true : false),
                });

            }else{
                document.getElementById('logout').click();
            }

            this.setState({
                isReady: true
            });
        });
    }

    updateProfile = async() => {
        let submit_enable = true;

        let phone = this.state.phone;
        phone = phone.replace(/ /g,'');
        phone = phone.replace(/_/g,'');
        phone = phone.replace(/-/g,'');
        phone = phone.replace('+','');
        phone = phone.replace(')','');
        phone = phone.replace('(','');
        if(phone.length < 11){
            this.setState({
                phoneError: true
            });
            submit_enable = false;
        }else{
            this.setState({
                phoneError: false
            });
        }

        if(this.state.name.length < 2){
            this.setState({
                nameError: true
            });
            submit_enable = false;
        }

        if(this.state.surname.length < 2){
            this.setState({
                surnameError: true
            });
            submit_enable = false;
        }
        if(this.state.patronymic.length < 2){
            this.setState({
                patronymicError: true
            });

            submit_enable = false;
        }

        if(submit_enable === true){
            this.setState({
                loading: true
            });

            const formData = new FormData();

            formData.append("name", this.state.name);
            formData.append("surname", this.state.surname);
            formData.append("patronymic", this.state.patronymic);
            formData.append("phone", this.state.phone);

            await fetch(API_URL + 'updateProfile', {
                method: "POST",
                body: formData,
                headers:{
                    'X-Auth-Token': localStorage.getItem('auth_key'),
                    Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
                }
            }).then((response) => response.json()).then((response) => {
                if(response.result === true){
                    this.modals.info2Show();

                    this.setState({
                        name_ds: true,
                        surname_ds: true,
                        phone_ds: true,
                        patronymic_ds: true,
                    });
                }else{
                    if(response.error.name) {
                        this.setState({
                            nameError: true,
                            nameErrorText: response.error.name[0]
                        });
                    }

                    if(response.error.surname) {
                        this.setState({
                            surnameError: true,
                            surnameErrorText: response.error.surname[0]
                        });
                    }

                    if(response.error.passport_from) {
                        this.setState({
                            fromError: true,
                            fromErrorText: response.error.passport_from[0]
                        });
                    }

                    if(response.error.phone) {
                        this.setState({
                            phoneError: true,
                            phoneErrorText: response.error.phone[0]
                        });
                    }
                }

                this.setState({
                    loading: false
                });
            });
        }
    }

    render() {
        if(localStorage.getItem('auth_key')){
            return (
                <>
                    <Header/>
                    <div className="content content--lk">
                        <div className="sc sc__docs">
                            <div className="container">
                                <div className="back__wrap d-flex">
                                    <Link to="/" className="d-flex align-items-center back__link">
                                        <i className="icon__back">
                                            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.146431 3.78072C-0.048831 3.97599 -0.048831 4.29257 0.146431 4.48783L3.32841 7.66981C3.52367 7.86507 3.84026 7.86507 4.03552 7.66981C4.23078 7.47455 4.23078 7.15797 4.03552 6.9627L1.20709 4.13428L4.03552 1.30585C4.23078 1.11059 4.23078 0.794006 4.03552 0.598743C3.84026 0.403481 3.52367 0.403481 3.32841 0.598743L0.146431 3.78072ZM9.60278 3.63428L0.499985 3.63428V4.63428L9.60278 4.63428V3.63428Z" fill="#1E151F"/>
                                            </svg>
                                        </i>
                                        <span>Назад</span>
                                    </Link>
                                </div>
                                <div className="sc__box-title">
                                    Заполните данные вашего профиля
                                </div>
                                <div className="lk__box lk__box--docs">
                                    <div className="form">
                                        <div className={'form__field ' + ((this.state.nameError) ? 'form__field--error' : '')}>
                                            <input
                                                value={this.state.name}
                                                type="text"
                                                placeholder="Имя"
                                                onChange={(name)=>{
                                                    this.setState({
                                                        name: name.target.value
                                                    });
                                                }}
                                                onFocus={()=>{
                                                    this.setState({
                                                        nameError: false
                                                    });
                                                }}
                                            />
                                            {this.state.nameError && (
                                                <div className="error_message">
                                                    {this.state.nameErrorText}
                                                </div>
                                            )}
                                        </div>
                                        <div className={'form__field ' + ((this.state.surnameError) ? 'form__field--error' : '')}>
                                            <input
                                                value={this.state.surname}
                                                type="text"
                                                placeholder="Фамилия"
                                                onChange={(surname)=>{
                                                    this.setState({
                                                        surname: surname.target.value
                                                    });
                                                }}
                                                onFocus={()=>{
                                                    this.setState({
                                                        surnameError: false
                                                    });
                                                }}
                                            />
                                            {this.state.surnameError && (
                                                <div className="error_message">
                                                    {this.state.surnameErrorText}
                                                </div>
                                            )}
                                        </div>
                                        <div className={'form__field ' + ((this.state.patronymicError) ? 'form__field--error' : '')}>
                                            <input
                                                value={this.state.patronymic}
                                                type="text"
                                                placeholder="Отчество"
                                                onChange={(patronymic)=>{
                                                    this.setState({
                                                        patronymic: patronymic.target.value
                                                    });
                                                }}
                                                onFocus={()=>{
                                                    this.setState({
                                                        patronymicError: false
                                                    });
                                                }}
                                            />
                                            {this.state.patronymicError && (
                                                <div className="error_message">
                                                    {this.state.patronymicErrorText}
                                                </div>
                                            )}
                                        </div>
                                        <div className={'form__field ' + ((this.state.phoneError) ? 'form__field--error' : '')}>
                                            <InputMask
                                                value={this.state.phone}
                                                type="text"
                                                mask="+7(999)999-99-99"
                                                disabled={this.state.phone_ds}
                                                placeholder="Номер телефона"
                                                autoComplete="nope"
                                                alwaysShowMask={false}
                                                onChange={(phone)=>{
                                                    this.setState({
                                                        phone: phone.target.value
                                                    });
                                                }}
                                                onFocus={()=>{
                                                    this.setState({
                                                        phoneError: false
                                                    });
                                                }}
                                            />
                                            {this.state.phoneError && (
                                                <div className="error_message">
                                                    {this.state.phoneErrorText}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div className={'form__action d-flex justify-content-center ' + ((this.state.loading === true) ? 'disabled' : '')}>
                                            <div onClick={()=>{
                                                this.updateProfile()
                                            }} className="btn d-flex align-items-center justify-content-center">
                                                Сохранить
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modals ref={instance => { this.modals = instance; }} page={'home'} goToScreen={this.goToScreen}/>
                </>

            )
        }else{
            return(
                <Redirect to="/login"/>
            )
        }
    }
}
