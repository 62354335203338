import React from 'react'

export default class Confirm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isReady: false,
            profile: {

            },
            document: {

            },
            manager: {

            },
            type: 1,
            needDocument: false
        }
    }

    findGetParameter = (parameterName) =>{
        let result = null,
            tmp = [];
        let items = window.location.search.substr(1).split("&");
        for (let index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        }
        return result;
    }

    componentDidMount() {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);

        let urlParams = window.location.search.substr(1);

        if(urlParams !== '' && urlParams !== undefined && urlParams !== null){
            let result = this.findGetParameter('result');
            let auth_key = this.findGetParameter('auth_key');

            if(parseInt(result) === 1 && auth_key !== null){
                localStorage.setItem('auth_key', auth_key);
                window.location.href = '/';
            }

        }
    }

    render (){
        return(
            <></>
        )
    }
}
