import React from 'react'
import {Link, Redirect} from 'react-router-dom'
import {Helmet} from "react-helmet"
import {isMobile, isIOS, isAndroid, isTablet} from 'react-device-detect'
import Modals from './Modals'
import Header from "./Header";

const API_URL = process.env.REACT_APP_API;

const faq  = [
    {
        quest: 'Какие сроки проведения Программы?',
        answ: 'Программа проводится в&nbsp;период с&nbsp;17&nbsp;июля по&nbsp;31&nbsp;августа 2024&nbsp;года.\n' +
            'АЗС победители определяются единоразово: до&nbsp;15&nbsp;сентября.\n' +
            'Поощрения начисляются после подведения итогов и&nbsp;подтверждения предоставленных Участников документов. Вручение осуществляется по&nbsp;30&nbsp;сентября.' +
            '<table><thead><tr><th></th><th>1&nbsp;этап (апрель 2024 года)</th><th>2&nbsp;этап (май 2024 года)</th></tr></thead><tbody><tr><td>Прием регистраций Участников</td><td>до&nbsp;21&nbsp;апреля</td><td>до&nbsp;19&nbsp;мая</td></tr><tr><td>Подведение итогов этапа</td><td>с&nbsp;01&nbsp;по&nbsp;14&nbsp;мая</td><td>с&nbsp;01&nbsp;по&nbsp;14&nbsp;июня</td></tr><tr><td>Прием документов Участников</td><td>до&nbsp;20&nbsp;мая</td><td>до&nbsp;20&nbsp;июня</td></tr><tr><td>Начисление поощрений</td><td>до&nbsp;31&nbsp;мая</td><td>до&nbsp;30&nbsp;июня</td></tr></tbody></table>' +
            'Более подробно со&nbsp;сроками и&nbsp;условиями Программы вы&nbsp;можете ознакомиться в&nbsp;<a href="/rules.pdf" target="_blank">Правилах Программы</a>.'
    },
    {
        quest: 'Как я&nbsp;пойму, что я&nbsp;победил?',
        answ: 'Победителям будет направлено уведомление в&nbsp;личном кабинете на&nbsp;Платформе. Победителям уведомление придет до&nbsp;15&nbsp;сентября включительно.\n' +
            'Важно: Участник должен загрузить документы до&nbsp;20&nbsp;сентября. Документы, загруженные позднее, не&nbsp;будут приняты.'
    },
    {
        quest: 'Как часто определяются победители?',
        answ: 'Победители определяются один раз по&nbsp;итогам проведения Программы. Участник может получить не&nbsp;более&nbsp;1 (одного) поощрения за&nbsp;весь период Программы.\n' +
            'Важно: Участник должен загрузить документы до&nbsp;20&nbsp;сентября. Документы, загруженные позднее, не&nbsp;будут приняты.'
    },
    {
        quest: 'Какое поощрение я&nbsp;получу в&nbsp;случае победы?',
        answ: 'Поощрением является сертификат OZON на&nbsp;сумму 10&nbsp;000&nbsp;руб. Обращаем внимание, что для его получения необходимо предоставить свои документы и&nbsp;оставить подпись в&nbsp;соответствующем поле на&nbsp;Платформе в&nbsp;разделе анкеты.'
    },
    {
        quest: 'Правила Программы',
        answ: 'С&nbsp;Правилами Программы вы&nbsp;можете дополнительно ознакомиться по&nbsp;<a href="/rules.pdf" target="_blank">ссылке</a>'
    },
    {
        quest: 'Что нужно сделать, чтобы получить поощрение?',
        answ: 'Для того, чтобы получить поощрение, Вашей АЗС нужно достичь наибольшей доли продаж холодных кофейных напитков в&nbsp;чашках внутри кофейной категории (но&nbsp;не&nbsp;менее&nbsp;5%) в&nbsp;рамках своего Региона. Общий рейтинг и&nbsp;положение вашей АЗС Вы&nbsp;можете найти на&nbsp;Платформе. Обращаем внимание, если сотрудники АЗС победителя не&nbsp;зарегистрировались до&nbsp;установленных сроков, они теряют право на&nbsp;получение поощрения. Более подробную информацию&nbsp;Вы можете уточнить у&nbsp;менеджера Вашей АЗС, а&nbsp;также направив запрос через обратную связь в&nbsp;Телеграм.'
    },
    {
        quest: 'Как мне получить поощрение?',
        answ: 'Если Ваша АЗС попала в&nbsp;список победителей, вам нужно:\n' +
            '1. Получить уведомление о&nbsp;победе в&nbsp;личном кабинете на&nbsp;Платформе.\n' +
            '2. Загрузить на&nbsp;Платформу свои документы (паспорт и&nbsp;ИНН) и&nbsp;оставить подпись в&nbsp;соответствующем поле в&nbsp;разделе анкеты.\n' +
            'Обращаем внимание, что на&nbsp;фото/скане паспорта должны быть видны все данные: ФИО, дата выдачи, серия и&nbsp;номер паспорта как на&nbsp;первом развороте, так и&nbsp;на&nbsp;прописке и&nbsp;т.д. \n' +
            '3. Дождаться уведомления в&nbsp;личном кабинете об&nbsp;успешной обработке данных.\n' +
            '4. Поощрение (сертификат) будет направлено по&nbsp;электронной почте, указанной в&nbsp;Вашем личном кабинете, в&nbsp;течение 5&nbsp;рабочих дней после определения победителей и&nbsp;подтверждения предоставленных данных.'
    },
    {
        quest: 'Куда придет мое поощрение?',
        answ: 'Поощрением является сертификат OZON на&nbsp;сумму 10&nbsp;000&nbsp;руб. передается на&nbsp;электронную почту Участника, которую&nbsp;Вы указали при регистрации и&nbsp;заполнении профиля.'
    },
    {
        quest: 'Должен&nbsp;ли я&nbsp;заплатить налог за&nbsp;полученное поощрение?',
        answ: 'Налог НДФЛ уплачивается Организатором в&nbsp;полном объеме&nbsp;&mdash; Вам самостоятельно не&nbsp;потребуется ничего оплачивать и/или предоставлять в&nbsp;ФНС России.'
    },
    {
        quest: 'Как я&nbsp;могу обновить паспортные данные на&nbsp;Платформе?',
        answ: 'Если персональные данные, указанные на&nbsp;Платформе, были изменены, то&nbsp;Участнику необходимо связаться с&nbsp;Оператором Программы, написав на&nbsp;почту lo_promo@progressiongroup.ru'
    },
    {
        quest: 'Безопасно&nbsp;ли загружать документы?',
        answ: 'Да, безопасно. При загрузке на&nbsp;Платформу Ваши документы передаются по&nbsp;защищенному подключению в&nbsp;хранилище, которое соответствует всем требованиям Закона &#8470;&nbsp;152-ФЗ &laquo;О&nbsp;персональных данных&raquo;.\n' +
            'Ваши данные собираются только с&nbsp;целью уплаты налога НДФЛ с&nbsp;полученных Вами поощрений, и&nbsp;будут переданы только ФНС России.'
    },
    {
        quest: 'Зачем нужно загружать ИНН?',
        answ: 'Если вы&nbsp;получили уведомление о&nbsp;необходимости загрузить фото/скан свидетельства ИНН, значит мы&nbsp;не&nbsp;смогли проверить Ваш номер ИНН по&nbsp;предоставленным Вами данным. Фото/скан свидетельства нужны для подтверждения вашего ИНН и&nbsp;уплаты налога НДФЛ.'
    },
    {
        quest: 'У меня нет свидетельства ИНН. Что делать?',
        answ: 'Вы&nbsp;можете предоставить скриншот из&nbsp;личного кабинета на&nbsp;сайте ФНС России из&nbsp;раздела &laquo;Сведения&raquo;. На&nbsp;скриншоте должны быть видны Ваши ФИО, номер ИНН, дата рождения, номер паспорта и&nbsp;другие данные.'
    },
    {
        quest: 'Зачем мне нужно указывать свои паспортные данные?',
        answ: 'Победителям необходимо предоставить свои паспортные данные и&nbsp;данные банковской карты, чтобы получить поощрение. Ваши персональные данные нужны только для уплаты налога НДФЛ и&nbsp;подачи данных в&nbsp;ФНС России. По&nbsp;завершении Программы паспортные данные и&nbsp;данные банковских карт будут удалены из&nbsp;системы.'
    },
    {
        quest: 'Могу&nbsp;ли я&nbsp;получить поощрение, не&nbsp;предоставляя данные?',
        answ: 'Поощрение можно получить только после предоставления корректных данных и&nbsp;их&nbsp;подтверждения со&nbsp;стороны Оператора Программы. Это необходимо для декларирования и&nbsp;подачи сведений в&nbsp;ФНС России.'
    },
    {
        quest: 'Как я&nbsp;увижу, что налог с&nbsp;поощрения был уплачен?',
        answ: 'Информация о&nbsp;полученном поощрении и&nbsp;уплаченном с&nbsp;него налоге НДФЛ появится в&nbsp;вашем личном кабинете ФНС России после марта 2025&nbsp;года, когда будут обработаны все поданные данные за&nbsp;2024&nbsp;год.'
    },
];

export default class Faq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            faq: -1,
            mess: '',
            topic: null,
            file: null,
            messError: false,
            topicError: false,
            messErrorText: 'Введите мин. 5 символов',
            topicErrorText: 'Выберите тему',
            topicInput: 'Тема обращения',
            topicDropdown: false,
            attachTitle: 'Прикрепите изображение',
            loading: false
        }

    }

    componentDidMount() {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);
    }

    toggleFaq = (faq) => {
        if(this.state.faq == faq){
            this.setState({
                faq: -1
            })
        }else{
            this.setState({
                faq
            })
        }
    }

    toggleTopicDropdown = () => {
        this.setState({
            topicDropdown: !this.state.topicDropdown,
        })
    }

    selectTopic = (topic, title) => {
        this.setState({
            topic: topic,
            topicError: false,
            topicDropdown: false,
            topicInput: title
        });
    }

    submitFeedback = async() => {
        let submit_enable = true;

         if(this.state.topic === null || this.state.topic === ''){
            this.setState({
                topicError: true
            })
             submit_enable = false;
         }

        if(this.state.mess.length < 5){
            this.setState({
                messError: true
            })
            submit_enable = false;
        }

        if(submit_enable === true){
            this.setState({
                loading: true
            });

            const formData = new FormData();
            const fileInput = document.querySelector('#file') ;
            formData.append("subject_id", this.state.topic);
            formData.append("name", localStorage.getItem('name'));
            formData.append("email", localStorage.getItem('email'));
            formData.append("text", this.state.mess);
            formData.append("accept1", 1);

            if(fileInput.files[0] !== undefined){
                formData.append('file', fileInput.files[0]);
            }

            await fetch(API_URL + 'sendFeedback', {
                method: "POST",
                body: formData,
                headers:{
                    'X-Auth-Token': localStorage.getItem('auth_key'),
                    Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
                }
            }).then((response) => response.json()).then((response) => {
                if(response.result === true){
                    this.modals.info3Show();

                    this.setState({
                        mess: '',
                        topic: null,
                        file: null,
                        messError: false,
                        topicError: false,
                        messErrorText: 'Введите мин. 5 символов',
                        topicErrorText: 'Выберите тему',
                        topicInput: 'Тема обращения',
                        topicDropdown: false,
                        attachTitle: 'Прикрепите изображение',
                    });

                    document.getElementById("file").value = "";
                }

                this.setState({
                    loading: false
                });
            });
        }
    }

    render() {
        if(localStorage.getItem('auth_key')){
            return (
                <>
                    <Header/>
                    <div className="content content--lk">
                        <div className="sc sc__faq">
                            <div className="container">
                                <div className="back__wrap d-flex">
                                    <Link to="/" className="d-flex align-items-center back__link">
                                        <i className="icon__back">
                                            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.146431 3.78072C-0.048831 3.97599 -0.048831 4.29257 0.146431 4.48783L3.32841 7.66981C3.52367 7.86507 3.84026 7.86507 4.03552 7.66981C4.23078 7.47455 4.23078 7.15797 4.03552 6.9627L1.20709 4.13428L4.03552 1.30585C4.23078 1.11059 4.23078 0.794006 4.03552 0.598743C3.84026 0.403481 3.52367 0.403481 3.32841 0.598743L0.146431 3.78072ZM9.60278 3.63428L0.499985 3.63428V4.63428L9.60278 4.63428V3.63428Z" fill="#1E151F"/>
                                            </svg>
                                        </i>
                                        <span>Назад</span>
                                    </Link>
                                </div>
                                <div className="sc__box-title">
                                    Вопросы и ответы
                                </div>
                                <div className="faq__box d-flex align-items-start">
                                    <div className="faq__box-left">
                                        <div className="faq__list">
                                            {faq.map((it, i) => (
                                                <div className={'faq__it ' + ((this.state.faq == (i + 1)) ? 'active' : '')}>
                                                    <div onClick={()=>{
                                                        this.toggleFaq(i + 1)
                                                    }} className="faq__it-header d-flex align-items-start justify-content-between">
                                                        <div className="faq__it-title" dangerouslySetInnerHTML={{__html: it.quest}}/>
                                                        <div className="faq__it-icon">
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.5 0H10.5V18H7.5V0Z" fill="#BC0033"/>
                                                                <path d="M0 10.5L0 7.5L18 7.5V10.5L0 10.5Z" fill="#BC0033"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="faq__it-answer fadeIn animated">
                                                        <div className="faq__it-txt" dangerouslySetInnerHTML={{__html: it.answ}}/>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="faq__box-right">
                                        <div className="form__gr">
                                            <div className="form__label">
                                                Тема сообщения
                                            </div>
                                            <div className={['form__field form__select ', ((this.state.topicError === true) ? 'has-error' : '')].join(' ')}>
                                                <div className={'select__custom ' + ((this.state.topicDropdown === true) ? 'opened' : '') + ((this.state.topic === null) ? ' empty' : '')}>
                                                    <div onClick={()=>this.toggleTopicDropdown()} className="select__custom-input d-flex align-items-center justify-content-between">
                                                        <span>{this.state.topicInput}</span>
                                                        <i className="icon__toggle">
                                                            <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="0.105469" y="0.707031" width="1" height="12" transform="rotate(-45 0.105469 0.707031)" fill="black"/>
                                                                <rect width="1" height="12" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 17.0857 0.707031)" fill="black"/>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className={'select__custom-dropdown ' + ((this.state.topicDropdown === true) ? 'active' : '')}>
                                                        <div className="select__custom-dropdown-list">
                                                            <div onClick={()=>this.selectTopic(1, 'Условия участия')} className={'select__custom-dropdown-item ' + ((this.state.topic === 1) ? 'active' : '')}>
                                                                Условия участия
                                                            </div>
                                                            <div onClick={()=>this.selectTopic(2, 'Призовой фонд')} className={'select__custom-dropdown-item ' + ((this.state.topic === 2) ? 'active' : '')}>
                                                                Призовой фонд
                                                            </div>
                                                            <div onClick={()=>this.selectTopic(3, 'Технические вопросы')} className={'select__custom-dropdown-item ' + ((this.state.topic === 3) ? 'active' : '')}>
                                                                Технические вопросы
                                                            </div>
                                                            <div onClick={()=>this.selectTopic(4, 'Другое')} className={'select__custom-dropdown-item ' + ((this.state.topic === 4) ? 'active' : '')}>
                                                                Другое
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {this.state.topicError && (
                                                    <div className="error_message">
                                                        {this.state.topicErrorText}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form__gr">
                                            <div className="form__label">
                                                Сообщение
                                            </div>
                                            <div className={'form__field ' + ((this.state.messError) ? 'form__field--error' : '')}>
                                            <textarea
                                                placeholder="Введите ваш комментарий"
                                                value={this.state.mess}
                                                onChange={(mess)=>{
                                                    this.setState({
                                                        mess: mess.target.value
                                                    });
                                                }}
                                                onFocus={()=>{
                                                    this.setState({
                                                        messError: false
                                                    });
                                                }}
                                            />
                                                {this.state.messError && (
                                                    <div className="error_message">
                                                        {this.state.messErrorText}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form__gr">
                                            <div className="form__label">
                                                Изображение
                                            </div>
                                            <div className="attach__field">
                                                <label className="d-flex">
                                                    <input type="file"
                                                           id="file"
                                                           accept="image/jpeg,image/png,application/pdf"
                                                           onChange={(f_file)=>{
                                                                this.setState({
                                                                    file: f_file.target.files[0],
                                                                    attachTitle: f_file.target.files[0].name
                                                                });
                                                            }}
                                                    />
                                                    <div className="attach__field-input d-flex align-items-center">
                                                        {this.state.attachTitle}
                                                    </div>
                                                    <div className="attach__btn">
                                                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect y="0.224609" width="40" height="40" rx="10" fill="#BC0033"/>
                                                            <path d="M28.6898 8.87674C25.6273 5.6739 20.6403 5.6739 17.5811 8.87674L9.09603 17.744C9.04076 17.8018 9.0115 17.88 9.0115 17.9616C9.0115 18.0432 9.04076 18.1214 9.09603 18.1792L10.2956 19.4339C10.3505 19.491 10.4247 19.523 10.5021 19.523C10.5795 19.523 10.6537 19.491 10.7085 19.4339L19.1936 10.5666C20.2469 9.46494 21.6481 8.85974 23.1371 8.85974C24.626 8.85974 26.0272 9.46494 27.0773 10.5666C28.1306 11.6682 28.7093 13.1336 28.7093 14.6874C28.7093 16.2446 28.1306 17.7066 27.0773 18.8083L18.4296 27.849L17.0285 29.3144C15.7183 30.6846 13.5889 30.6846 12.2788 29.3144C11.6448 28.6514 11.297 27.7708 11.297 26.8323C11.297 25.8939 11.6448 25.0133 12.2788 24.3503L20.8581 15.381C21.0759 15.1566 21.362 15.0308 21.6676 15.0308H21.6709C21.9765 15.0308 22.2593 15.1566 22.4739 15.381C22.6917 15.6088 22.8087 15.908 22.8087 16.2276C22.8087 16.5438 22.6884 16.843 22.4739 17.0674L15.4615 24.3945C15.4062 24.4523 15.3769 24.5305 15.3769 24.6121C15.3769 24.6937 15.4062 24.7719 15.4615 24.8297L16.6611 26.0843C16.7159 26.1414 16.7901 26.1735 16.8675 26.1735C16.9449 26.1735 17.0191 26.1414 17.074 26.0843L24.0831 18.7538C24.73 18.0772 25.0844 17.1796 25.0844 16.2242C25.0844 15.2688 24.7268 14.3678 24.0831 13.6946C22.7469 12.2972 20.5753 12.3006 19.2391 13.6946L18.4069 14.5684L10.663 22.6639C10.1374 23.2104 9.7208 23.8605 9.4373 24.5766C9.15381 25.2928 9.00908 26.0607 9.0115 26.8357C9.0115 28.41 9.59993 29.889 10.663 31.0008C11.7651 32.15 13.2085 32.7246 14.652 32.7246C16.0954 32.7246 17.5389 32.15 18.6377 31.0008L28.6898 20.4947C30.169 18.9443 30.9882 16.8804 30.9882 14.6874C30.9915 12.491 30.1722 10.4272 28.6898 8.87674Z" fill="white"/>
                                                        </svg>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form__gr form__gr--actions">
                                            <div className="d-flex">
                                                <div className={'form__action d-flex ' + ((this.state.loading === true) ? 'disabled' : '')}>
                                                    <div onClick={()=>{
                                                        this.submitFeedback()
                                                    }} className="btn d-flex align-items-center justify-content-center">
                                                        Отправить
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modals ref={instance => { this.modals = instance; }} page={'home'} goToScreen={this.goToScreen}/>
                </>

            )
        }else{
            return(
                <Redirect to="/login"/>
            )
        }
    }
}
