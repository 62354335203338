import React from 'react'
import {Link, Redirect} from 'react-router-dom'
import {Helmet} from "react-helmet"
import ReactWOW from 'react-wow';
import InputMask from 'react-input-mask'
import {isMobile, isIOS, isAndroid, isTablet} from 'react-device-detect'
import CustomScroll from 'react-custom-scroll';
import Slider from "react-slick"
import Modals from './Modals'

const API_URL = process.env.REACT_APP_API;

let sl_set = {};

let myMap;

export default class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isReady: false,
        }
    }

    componentDidMount() {

    }

    componentWillMount(){

    }

    render() {
        return(
            <div className="header">
                <div className="container d-flex align-items-center justify-content-between">
                    <Link to="/" className="header__title">
                        Клуб кофейных знатоков
                    </Link>
                    <div className="header__actions d-flex align-items-center">
                        <Link to="/faq" className="faq__link">
                            <svg width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M50.3597 25.6017C50.3597 38.9553 39.5355 49.7804 26.1833 49.7804C12.8312 49.7804 2.00693 38.9553 2.00693 25.6017C2.00693 12.248 12.8312 1.42295 26.1833 1.42295C39.5355 1.42295 50.3597 12.248 50.3597 25.6017Z" stroke="#1E151F" stroke-width="2.17793"/>
                                <path d="M25.9259 29.9709C26.7942 29.9709 27.364 29.3739 27.364 28.5055V26.3616C30.1047 25.7375 31.977 23.9464 31.977 20.9884C31.977 17.8676 29.3177 15.7238 25.953 15.7238C23.7008 15.7238 21.3129 17.2163 20.526 19.3059C20.0647 20.5271 20.8516 21.3412 21.72 21.3412C22.2084 21.3412 22.6968 21.097 22.9953 20.4999C23.5923 19.0888 24.5691 18.4646 26.0073 18.4646C27.6082 18.4646 28.8565 19.4959 28.8565 21.1241C28.8565 22.3453 28.2595 23.4579 26.4686 23.865C24.7591 24.2449 24.4606 24.5163 24.4606 25.656V28.5055C24.4606 29.3739 25.0304 29.9709 25.9259 29.9709ZM25.9259 35.4798C26.8213 35.4798 27.554 34.7471 27.554 33.8515C27.554 32.956 26.8213 32.2233 25.9259 32.2233C25.0304 32.2233 24.2978 32.956 24.2978 33.8515C24.2978 34.7471 25.0304 35.4798 25.9259 35.4798Z" fill="#1E151F"/>
                            </svg>
                        </Link>
                        <div onClick={()=>{
                            localStorage.removeItem('auth_key');
                            localStorage.removeItem('name');
                            localStorage.removeItem('email');
                            localStorage.removeItem('phone');
                            window.location.reload();
                        }} className="logout__link" id="logout">
                            <svg width="38" height="48" viewBox="0 0 38 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.0329 1.18384H12.081C6.06686 1.18384 1.19141 6.0593 1.19141 12.0735V35.7427C1.19141 41.7569 6.06686 46.6324 12.081 46.6324H27.0329" stroke="#1E151F" stroke-width="2.17793" stroke-linecap="round"/>
                                <path d="M13.1583 22.8191C12.5569 22.8191 12.0694 23.3067 12.0694 23.9081C12.0694 24.5095 12.5569 24.997 13.1583 24.997V22.8191ZM36.7699 24.6781C37.1952 24.2528 37.1952 23.5633 36.7699 23.1381L29.8398 16.2079C29.4145 15.7827 28.7251 15.7827 28.2998 16.2079C27.8745 16.6332 27.8745 17.3227 28.2998 17.748L34.4599 23.9081L28.2998 30.0682C27.8745 30.4935 27.8745 31.183 28.2998 31.6082C28.7251 32.0335 29.4145 32.0335 29.8398 31.6082L36.7699 24.6781ZM13.1583 24.997H35.9999V22.8191H13.1583V24.997Z" fill="#1E151F"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
