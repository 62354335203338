import React from 'react'
import "../assets/fonts/stylesheet.css"
import "../assets/css/bootstrap.min.css"
import "../assets/css/animate.css"
import "slick-carousel/slick/slick.css"
import "../assets/css/style.scss"
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import Login from './Login'
import Notifications from './Notifications'
/*import Invite from './Invite'*/
import Reg from './Reg'
import Documents from './Documents'
import Rating from './Rating'
import Faq from './Faq'
/*import Prizes from './Prizes'*/
import Profile from './Profile'
/*import Statistic from './Statistic'
import SendCups from "./SendCups";*/
import Confirm from "./Confirm";
require('es6-promise').polyfill();
require('isomorphic-fetch');

export default class Main extends React.Component {
    render(){
        return(
            <Switch>
                <Route exact path='/' component={Home}/>
                <Route exact path='/confirm' component={Confirm}/>
                <Route exact path='/login' component={Login}/>
                <Route exact path='/notifications' component={Notifications}/>
                <Route exact path='/documents' component={Documents}/>
                <Route exact path='/profile' component={Profile}/>
                {/*<Route path='/invite' component={Invite}/>*/}
                {/*<Route path='/registration' component={Reg}/>*/}
                <Route path='/rating' component={Rating}/>
                <Route path='/faq' component={Faq}/>
                {/*<Route path='/statistic' component={Statistic}/>
                <Route path='/send-cups' component={SendCups}/>
                <Route path='/prizes' component={Prizes}/>*/}
            </Switch>
        )
    }
}
