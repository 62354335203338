import React from 'react'
import {Link, Redirect} from 'react-router-dom'
import {Helmet} from "react-helmet"
import {isMobile, isIOS, isAndroid, isTablet} from 'react-device-detect'
import Modals from './Modals'
import Header from "./Header";

const API_URL = process.env.REACT_APP_API;

let sl_set = {};

export default class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            profile: {},
            document: {},
            manager: {},
            type: 1,
            not: [],
            loading: false,
            procId: -1
        }
    }

    componentDidMount() {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);

        if(localStorage.getItem('auth_key')){
            this.getNotifications();
        }
    }

    checkNotification = async(id) => {
        this.setState({
            loading: true
        });

        const formData = new FormData();
        formData.append("notification_id", id);
        formData.append("prize_id", id);

        await fetch(API_URL + 'checkNotification', {
            method: "POST",
            body: formData,
            headers:{
                'X-Auth-Token': localStorage.getItem('auth_key'),
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result === true) {
                this.getNotifications()
            }
        });
    }

    getNotifications = async () => {
        await fetch(API_URL + 'getNotifications', {
            method: "GET",
            headers:{
                'X-Auth-Token': localStorage.getItem('auth_key'),
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                this.setState({
                    not: response.data.rows
                });
            }else{
                document.getElementById('logout').click();
            }

            this.setState({
                loading: false
            });

            this.setState({
                isReady: true
            });
        });
    }

    render() {
        if(localStorage.getItem('auth_key')){
            return (
                <>
                    <Header/>
                    <div className="content content--lk">
                        <div className="sc sc__notifications">
                            <div className="container">
                                <div className="back__wrap d-flex">
                                    <Link to="/" className="d-flex align-items-center back__link">
                                        <i className="icon__back">
                                            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.146431 3.78072C-0.048831 3.97599 -0.048831 4.29257 0.146431 4.48783L3.32841 7.66981C3.52367 7.86507 3.84026 7.86507 4.03552 7.66981C4.23078 7.47455 4.23078 7.15797 4.03552 6.9627L1.20709 4.13428L4.03552 1.30585C4.23078 1.11059 4.23078 0.794006 4.03552 0.598743C3.84026 0.403481 3.52367 0.403481 3.32841 0.598743L0.146431 3.78072ZM9.60278 3.63428L0.499985 3.63428V4.63428L9.60278 4.63428V3.63428Z" fill="#1E151F"/>
                                            </svg>
                                        </i>
                                        <span>Назад</span>
                                    </Link>
                                </div>
                                <div className="not__box">
                                    <div className="sc__box-title">
                                        Уведомления
                                    </div>

                                    {this.state.not.length > 0 && (
                                        <div className={'not__list ' + ((this.state.loading) ? 'disabled' : '')}>
                                            {this.state.not.map((nt, i) => (
                                                <div onClick={()=>{
                                                    if(nt.status == 0){
                                                        this.checkNotification(nt.id)
                                                    }
                                                }} className={'not__it ' + ((nt.status == 0) ? 'not__it--marked' : '')}>
                                                    {nt.text}
                                                </div>
                                            ))}
                                            {/*<div className="not__it not__it--important">
                                            Через 12 дней заканчивается первый
                                            период программы, за которым следует
                                        </div>*/}
                                        </div>
                                    )}

                                    {this.state.not.length == 0 && (
                                        <div className="sc__box-descr">
                                            У вас нет актуальных уведомлений.
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                    <Modals ref={instance => { this.modals = instance; }} page={'home'} goToScreen={this.goToScreen}/>
                </>

            )
        }else{
            return(
                <Redirect to="/login"/>
            )
        }
    }
}
