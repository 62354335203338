import React from 'react'
import {Link, Redirect} from 'react-router-dom'
import {Helmet} from "react-helmet"
import ReactWOW from 'react-wow';
import InputMask from 'react-input-mask'
import {isMobile, isIOS, isAndroid, isTablet} from 'react-device-detect'
import CustomScroll from 'react-custom-scroll';
import Slider from "react-slick"
import Modals from './Modals'
import Header from "./Header";

const API_URL = process.env.REACT_APP_API;

let sl_set = {};

export default class Reg extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isReady: false,
            numb: '',
            numbError: false,
            numbErrorText: 'Введи корректный номер АЗС',
            name: '',
            nameError: false,
            nameErrorText: 'Введи корректное Имя',
            surname: '',
            surnameError: false,
            surnameErrorText: 'Введи корректную Фамилию',
            email: '',
            emailError: false,
            emailErrorText: 'Введи корректный e-mail',
            password: '',
            passwordError: false,
            passwordErrorText: 'Введи корректный пароль',
            rules1: false,
            rules1Error: false,
            rules1ErrorText: 'Необходимо согласие',
            rules2: false,
            rules2Error: false,
            rules2ErrorText: 'Необходимо согласие',
            rules3Error: false,
            rules3ErrorText: 'Необходимо согласие',
            loading: false,
            invite_text: 'Привет! Это твоё приглашение <br/>в клуб кофейных знатоков',
            valid: false,
            promo: '',
            promoError: false,
            promoErrorText: 'Пригласительный код недействителен',
            phone: '',
            phoneError: false,
            phoneErrorText: 'Некорректный телефон',
            region: null,
            regionErrorText: 'Выберите регион',
            regionInput: 'Регион',
            regionDropdown: false,
        }
    }

    findGetParameter = (parameterName) =>{
        let result = null,
            tmp = [];
        let items = window.location.search.substr(1).split("&");
        for (let index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        }
        return result;
    }

    componentDidMount() {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);
    }

    toggleRegionDropdown = () => {
        this.setState({
            regionDropdown: !this.state.regionDropdown,
        })
    }

    selectRegion = (region, title) => {
        this.setState({
            region: region,
            regionError: false,
            regionDropdown: false,
            regionInput: title
        });
    }

    checkPromo = async() => {
        if(this.state.promo.length < 2){
            this.setState({
                promoError: true,
            });
        }else{
            this.setState({
                loading: true
            });
            const formData = new FormData();
            formData.append("promo", this.state.promo);
            await fetch(API_URL + 'checkPromo2', {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
                }
            }).then((response) => response.json()).then((response) => {
                if(response.result){
                    let azs = response.data.numberAzs;
                    this.setState({
                        valid: true,
                        loading: false,
                        numb: azs
                    });
                }else{
                    if(response.error.promo){
                        this.setState({
                            promoError: true,
                            valid: false,
                            promoErrorText: response.error.promo[0],
                            loading: false
                        });
                    }

                    if(response.error){
                        this.setState({
                            promoError: true,
                            valid: false,
                            promoErrorText: response.error,
                            loading: false
                        });
                    }

                }
            });
        }
    }

    componentWillMount(){
        sl_set = {
            infinite: true,
            dots: false,
            arrows: false,
            fade: true,
            waitForAnimate: false,
            /*autoplaySpeed: 3000,
            autoplay: true,*/
            pauseOnHover: false,
            beforeChange: (oldIndex, newIndex) => {
                this.setState({
                    cur_pr: (newIndex + 1),
                    sliding: true
                })
            },
            afterChange: (newIndex) => {
                this.setState({
                    sliding: false
                })
            }
        }
    }

    submitReg = async() => {
        let submit_enable = true;

        if(this.state.email === ''){
            this.setState({
                emailError: true
            });
            submit_enable = false;
        }else{
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!re.test(this.state.email)){
                this.setState({
                    emailError: true
                });
                submit_enable = false;
            }else{
                this.setState({
                    emailError: false
                });
            }
        }

        if(this.state.name.length < 2){
            this.setState({
                nameError: true
            });
            submit_enable = false;
        }

        if(this.state.surname.length < 2){
            this.setState({
                surnameError: true
            });
            submit_enable = false;
        }

        let phone = this.state.phone;
        phone = phone.replace(/ /g,'');
        phone = phone.replace(/_/g,'');
        phone = phone.replace(/-/g,'');
        phone = phone.replace('+','');
        phone = phone.replace(')','');
        phone = phone.replace('(','');
        if(phone.length < 11){
            this.setState({
                phoneError: true
            });
            submit_enable = false;
        }else{
            this.setState({
                phoneError: false
            });
        }

        if(this.state.rules1 !== true){
            this.setState({
                rules1Error: true
            });
            submit_enable = false;
        }

        if(this.state.rules2 !== true){
            this.setState({
                rules2Error: true
            });
            submit_enable = false;
        }

        if(this.state.rules3 !== true){
            this.setState({
                rules3Error: true
            });
            submit_enable = false;
        }


        if(submit_enable === true){
            this.setState({
                loading: true
            });

            const formData = new FormData();
            formData.append("login", this.state.email);
            formData.append("numberAzs", this.state.numb);
            formData.append("name", this.state.name);
            formData.append("surname", this.state.surname);
            formData.append("region", this.state.region);
            formData.append("phone", this.state.phone);
            formData.append("promo", this.state.promo);

            await fetch(API_URL + 'register2', {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
                }
            }).then((response) => response.json()).then((response) => {
                if(response.result === true){
                    this.modals.info5Show();

                    setTimeout(function (){
                        window.location.href = '/';
                    }, 4000);
                }else{
                    if(response.error.login) {
                        this.setState({
                            emailError: true,
                            emailErrorText: response.error.login[0]
                        });
                    }

                    if(response.error.promo) {
                        this.setState({
                            numbError: true,
                            numbErrorText: response.error.promo[0]
                        });
                    }

                    if(response.error.name) {
                        this.setState({
                            nameError: true,
                            nameErrorText: response.error.name[0]
                        });
                    }

                    if(response.error.surname) {
                        this.setState({
                            surnameError: true,
                            surnameErrorText: response.error.surname[0]
                        });
                    }

                    if(response.error.numberAzs) {
                        this.setState({
                            numbError: true,
                            numbErrorText: response.error.numberAzs[0]
                        });
                    }
                }

                this.setState({
                    loading: false
                });
            });
        }
    }

    render() {
        return (
            <>
                <div className="content">
                    <div className="sc sc__invite sc__full">
                        <div className="container">
                            <div className="invite__box">
                                <div className="invite__box-header d-flex align-items-center justify-content-center">
                                    <div className="logo">
                                        <img src={require("../assets/img/logo.svg").default}/>
                                    </div>
                                    <div className="sc__header">
                                        <div className="sc__subtitle">
                                            Мотивационная программа
                                        </div>
                                        <div className="sc__title">
                                            Клуб кофейных знатоков
                                        </div>
                                    </div>
                                </div>
                                {!this.state.valid && (
                                    <div className="invite__title text-center" dangerouslySetInnerHTML={{__html: this.state.invite_text}}/>
                                )}

                                {this.state.valid && (
                                    <>
                                        <div className="form">
                                            <div className={'form__field ' + ((this.state.numbError) ? 'form__field--error' : '')}>
                                                <input
                                                    value={this.state.numb}
                                                    type="text"
                                                    readOnly
                                                    disabled
                                                    placeholder="Номер АЗС"
                                                    onChange={(numb)=>{
                                                        this.setState({
                                                            numb: numb.target.value
                                                        });
                                                    }}
                                                    onFocus={()=>{
                                                        this.setState({
                                                            numbError: false
                                                        });
                                                    }}
                                                />
                                                {this.state.numbError && (
                                                    <div className="error_message">
                                                        {this.state.numbErrorText}
                                                    </div>
                                                )}
                                            </div>
                                            <div className={'form__field ' + ((this.state.surnameError) ? 'form__field--error' : '')}>
                                                <input
                                                    value={this.state.surname}
                                                    type="text"
                                                    placeholder="Фамилия"
                                                    onChange={(surname)=>{
                                                        this.setState({
                                                            surname: surname.target.value
                                                        });
                                                    }}
                                                    onFocus={()=>{
                                                        this.setState({
                                                            surnameError: false
                                                        });
                                                    }}
                                                />
                                                {this.state.surnameError && (
                                                    <div className="error_message">
                                                        {this.state.surnameErrorText}
                                                    </div>
                                                )}
                                            </div>
                                            <div className={'form__field ' + ((this.state.nameError) ? 'form__field--error' : '')}>
                                                <input
                                                    value={this.state.name}
                                                    type="text"
                                                    placeholder="Имя"
                                                    onChange={(name)=>{
                                                        this.setState({
                                                            name: name.target.value
                                                        });
                                                    }}
                                                    onFocus={()=>{
                                                        this.setState({
                                                            nameError: false
                                                        });
                                                    }}
                                                />
                                                {this.state.nameError && (
                                                    <div className="error_message">
                                                        {this.state.nameErrorText}
                                                    </div>
                                                )}
                                            </div>
                                            <div className={'form__field ' + ((this.state.emailError) ? 'form__field--error' : '')}>
                                                <input
                                                    value={this.state.email}
                                                    type="text"
                                                    placeholder="E-mail"
                                                    onChange={(login)=>{
                                                        this.setState({
                                                            email: login.target.value
                                                        });
                                                    }}
                                                    onFocus={()=>{
                                                        this.setState({
                                                            emailError: false
                                                        });
                                                    }}
                                                />
                                                {this.state.emailError && (
                                                    <div className="error_message">
                                                        {this.state.emailErrorText}
                                                    </div>
                                                )}
                                            </div>
                                            <div className={'form__field ' + ((this.state.phoneError) ? 'form__field--error' : '')}>
                                                <InputMask
                                                    value={this.state.phone}
                                                    type="text"
                                                    mask="+7(999)999-99-99"
                                                    placeholder="Номер телефона"
                                                    autoComplete="nope"
                                                    alwaysShowMask={false}
                                                    onChange={(phone)=>{
                                                        this.setState({
                                                            phone: phone.target.value
                                                        });
                                                    }}
                                                    onFocus={()=>{
                                                        this.setState({
                                                            phoneError: false
                                                        });
                                                    }}
                                                />
                                                {this.state.phoneError && (
                                                    <div className="error_message">
                                                        {this.state.phoneErrorText}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="checkbox__list">
                                                <div className={'checkbox__item ' + ((this.state.rules1Error === true) ? 'has-error' : '')}>
                                                    <div className="checkbox__wrap">
                                                        <label>
                                                            <input type="checkbox" name="agree1" onChange={(agree1)=>{
                                                                this.setState({
                                                                    rules1: agree1.target.checked,
                                                                    rules1Error: false
                                                                });
                                                            }}/>
                                                            <div className="checkbox__decor"/>
                                                            <div className="checkbox__title">
                                                                Согласен с условиями <a href="/rules.pdf" target="_blank">Правил Программы</a>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    {this.state.rules1Error && (
                                                        <div className="error_message">
                                                            {this.state.rules1ErrorText}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={'checkbox__item ' + ((this.state.rules2Error === true) ? 'has-error' : '')}>
                                                    <div className="checkbox__wrap">
                                                        <label>
                                                            <input type="checkbox" name="agree2" onChange={(agree2)=>{
                                                                this.setState({
                                                                    rules2: agree2.target.checked,
                                                                    rules2Error: false
                                                                });
                                                            }}/>
                                                            <div className="checkbox__decor"/>
                                                            <div className="checkbox__title">
                                                                Согласен на <a href="/personal_data_consent.pdf" target="_blank">обработку персональных данных</a>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    {this.state.rules2Error && (
                                                        <div className="error_message">
                                                            {this.state.rules2ErrorText}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={'checkbox__item ' + ((this.state.rules3Error === true) ? 'has-error' : '')}>
                                                    <div className="checkbox__wrap">
                                                        <label>
                                                            <input type="checkbox" name="agree3" onChange={(agree3)=>{
                                                                this.setState({
                                                                    rules3: agree3.target.checked,
                                                                    rules3Error: false
                                                                });
                                                            }}/>
                                                            <div className="checkbox__decor"/>
                                                            <div className="checkbox__title">
                                                                Согласен на получение <a href="/delivery_consent.pdf" target="_blank">информационной рассылки и уведомлений</a>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    {this.state.rules3Error && (
                                                        <div className="error_message">
                                                            {this.state.rules3ErrorText}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className={'form__action d-flex justify-content-center ' + ((this.state.loading === true) ? 'disabled' : '')}>
                                                <div onClick={()=>{
                                                    this.submitReg()
                                                }} className="btn d-flex align-items-center justify-content-center">
                                                    Зарегистрироваться
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {!this.state.valid && (
                                    <>
                                        <div className="form">
                                            <div className={'form__field ' + ((this.state.promoError) ? 'form__field--error' : '')}>
                                                <input
                                                    value={this.state.promo}
                                                    type="text"
                                                    placeholder="Пригласительный код"
                                                    onChange={(promo)=>{
                                                        this.setState({
                                                            promo: promo.target.value
                                                        });
                                                    }}
                                                    onFocus={()=>{
                                                        this.setState({
                                                            promoError: false
                                                        });
                                                    }}
                                                />
                                                {this.state.promoError && (
                                                    <div className="error_message">
                                                        {this.state.promoErrorText}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className={'form__action d-flex justify-content-center ' + ((this.state.loading === true) ? 'disabled' : '')}>
                                                <div onClick={()=>{
                                                    this.checkPromo()
                                                }} className="btn d-flex align-items-center justify-content-center">
                                                    Продолжить регистрацию
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}


                                {/*{!this.state.isReady && (
                                    <img src={require("../assets/img/loader-dark.svg").default} className="loader__img"/>
                                )}*/}
                            </div>
                        </div>
                    </div>
                </div>
                <Modals ref={instance => { this.modals = instance; }} page={'invite'} goToScreen={this.goToScreen}/>
            </>

        )
    }
}
