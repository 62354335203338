import React from 'react'
import {Link, Redirect} from 'react-router-dom'
import {Helmet} from "react-helmet"
import ReactWOW from 'react-wow';
import InputMask from 'react-input-mask'
import {isMobile, isIOS, isAndroid, isTablet} from 'react-device-detect'
import CustomScroll from 'react-custom-scroll';
import Slider from "react-slick"
import CanvasDraw from "react-canvas-draw";
import Modals from './Modals'
import Header from "./Header";

const API_URL = process.env.REACT_APP_API;

let sl_set = {};

function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

function dataURItoBlob(dataURI) {
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    let ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
}

export default class Documents extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isReady: false,
            canvasInit: true,
            document: {

            },
            inn: '',
            innFile: null,
            address: '',
            from: '',
            passNumb: '',
            passSerial: '',
            passFile1: null,
            passFile2: null,

            bank_rs: '',
            bank_ks: '',
            bank_kpp: '',
            bank_bik: '',
            bank_inn: '',
            bank_name: '',

            signError: false,
            signErrorText: 'Сделайте роспись',

            innError: false,
            innFileError: false,
            passFileError: false,
            addressError: false,
            fromError: false,
            passSerialError: false,
            rules1: false,
            rules1Error: false,
            rules1ErrorText: 'Необходимо согласие',

            innErrorText: 'Некорректный ИНН',
            innFileErrorText: 'Прикрепите файл',
            passFileErrorText: 'Прикрепите файл',
            addressErrorText: 'Заполните поле',
            fromErrorText: 'Заполните поле',
            passSerialErrorText: 'Некорректные Серия/Номер',

            bank_rsError: false,
            bank_ksError: false,
            bank_kppError: false,
            bank_bikError: false,
            bank_innError: false,
            bank_nameError: false,

            bank_rsErrorText: 'Некорректный р/с',
            bank_ksErrorText: 'Некорректный к/с',
            bank_kppErrorText: 'Некорректный КПП',
            bank_bikErrorText: 'Некорректный БИК',
            bank_innErrorText: 'Некорректный ИНН',
            bank_nameErrorText: 'Некорректное имя',

            delivery_fio: '',
            delivery_fioError: false,
            delivery_fioErrorText: 'Некорректный ФИО',

            delivery_phone: '',
            delivery_phoneError: false,
            delivery_phoneErrorText: 'Некорректный телефон',

            date_birth: '',
            date_birthError: false,
            date_birthErrorText: 'Некорректная дата рождения',

            document_ds: false,
            inn_ds: false,
            innFile_ds: false,
            address_ds: false,
            from_ds: false,
            passNumb_ds: false,
            passSerial_ds: false,
            passFile1_ds: false,
            passFile2_ds: false,

            bank_rs_ds: false,
            bank_ks_ds: false,
            bank_kpp_ds: false,
            bank_bik_ds: false,
            bank_inn_ds: false,
            bank_name_ds: false,

            delivery_fio_ds: false,
            delivery_phone_ds: false,
            date_birth_ds: false,
            editable: false
        }
    }

    findGetParameter = (parameterName) =>{
        let result = null,
            tmp = [];
        let items = window.location.search.substr(1).split("&");
        for (let index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        }
        return result;
    }

    componentDidMount() {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);

        let urlParams = window.location.search.substr(1);
        if(urlParams !== '' && urlParams !== undefined && urlParams !== null){
            let utm_source = this.findGetParameter('utm_source');

            if(utm_source !== null && utm_source !== '' && utm_source !== undefined){
                window.location.href = '/game?utm_source=' + utm_source;
            }
        }

        if(localStorage.getItem('auth_key')){
            this.getProfile();
        }
    }

    componentWillMount(){
        sl_set = {
            infinite: true,
            dots: false,
            arrows: false,
            fade: true,
            waitForAnimate: false,
            /*autoplaySpeed: 3000,
            autoplay: true,*/
            pauseOnHover: false,
            beforeChange: (oldIndex, newIndex) => {
                this.setState({
                    cur_pr: (newIndex + 1),
                    sliding: true
                })
            },
            afterChange: (newIndex) => {
                this.setState({
                    sliding: false
                })
            }
        }
    }

    getProfile = async () => {
        await fetch(API_URL + 'getProfile', {
            method: "GET",
            headers:{
                'X-Auth-Token': localStorage.getItem('auth_key'),
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                let data = response.data;
                let ds_inp = this.state.ds_inp;

                this.setState({
                    document: data.document,
                    inn: data.document.inn_number,
                    innFile: data.document.inn_photo,
                    address: data.document.passport_address,
                    from: data.document.passport_from,
                    passNumb: data.document.passport_number,
                    passSerial: data.document.passport_serial,
                    passFile1: data.document.passport_photo1,
                    passFile2: data.document.passport_photo2,
                    bank_rs: data.bank.bank_rs,
                    bank_ks: data.bank.bank_ks,
                    bank_kpp: data.bank.bank_kpp,
                    bank_bik: data.bank.bank_bik,
                    bank_inn: data.bank.bank_inn,
                    bank_name: data.bank.bank_name,
                    delivery_fio: data.document.delivery_fio,
                    delivery_phone: data.document.delivery_phone,
                    date_birth: data.document.date_birth
                });


                /*this.setState({
                    document_ds: (data.document && data.document.status != 2 ? true : false),
                    inn_ds: (data.document.inn_number && data.document.status != 2 ? true : false),
                    innFile_ds: (data.document.inn_photo && data.document.status != 2 ? true : false),
                    address_ds: (data.document.passport_address && data.document.status != 2 ? true : false),
                    from_ds: (data.document.passport_from && data.document.status != 2 ? true : false),
                    passNumb_ds: (data.document.passport_number && data.document.status != 2 ? true : false),
                    passSerial_ds: (data.document.passport_serial && data.document.status != 2 ? true : false),
                    passFile1_ds: (data.document.passport_photo1 && data.document.status != 2 ? true : false),
                    passFile2_ds: (data.document.passport_photo2 && data.document.status != 2 ? true : false),
                    bank_rs_ds: (data.document.bank_rs && data.document.status != 2? true : false),
                    bank_ks_ds: (data.document.bank_ks && data.document.status != 2 ? true : false),
                    bank_kpp_ds: (data.document.bank_kpp && data.document.status != 2 ? true : false),
                    bank_bik_ds: (data.document.bank_bik && data.document.status != 2 ? true : false),
                    bank_inn_ds: (data.document.bank_inn && data.document.status != 2 ? true : false),
                    bank_name_ds: (data.document.bank_name && data.document.status != 2 ? true : false),
                    delivery_fio_ds: data.document.delivery_fio,
                    delivery_phone_ds: data.document.delivery_phone,
                    date_birth_ds: data.document.date_birth,
                    editable: (data.document.status == 2)
                });*/

            }else{
                document.getElementById('logout').click();
            }

            this.setState({
                isReady: true
            });
        });
    }

    updateProfile = async() => {
        let submit_enable = true;

        const fileInput = document.querySelector('#file') ;
        const fileInput2 = document.querySelector('#file2') ;
        const fileInput3 = document.querySelector('#file3') ;

        if(!this.state.delivery_fio){
            this.setState({
                delivery_fioError: true
            });
            submit_enable = false;
        }

        if(!this.state.delivery_fio){
            this.setState({
                delivery_fioError: true
            });
            submit_enable = false;
        }

        let phone = this.state.delivery_phone;
        phone = phone.replace(/ /g,'');
        phone = phone.replace(/_/g,'');
        phone = phone.replace(/-/g,'');
        phone = phone.replace('+','');
        phone = phone.replace(')','');
        phone = phone.replace('(','');

        if(phone.length < 11){
            this.setState({
                delivery_phoneError: true
            });
            submit_enable = false;
        }else{
            this.setState({
                delivery_phoneError: false
            });
        }

        let dateBirth = this.state.date_birth;
        dateBirth = dateBirth.replace(/ /g,'');
        dateBirth = dateBirth.replace(/_/g,'');


        if(dateBirth.length < 10){
            this.setState({
                date_birthError: true
            });
            submit_enable = false;
        }else{
            this.setState({
                date_birthError: false
            });
        }

        let passSerial = this.state.passSerial;
        passSerial = passSerial.replace(/ /g,'');
        passSerial = passSerial.replace(/_/g,'');


        if(passSerial.length < 10){
            this.setState({
                passSerialError: true
            });
            submit_enable = false;
        }else{
            this.setState({
                passSerialError: false
            });
        }

        if(!this.state.address || this.state.address.length < 6){
            this.setState({
                addressError: true
            });
            submit_enable = false;
        }

        if(this.state.canvasInit){
            this.setState({
                signError: true
            });
            submit_enable = false;
        }

        /*if(!this.state.from || this.state.from.length < 6){
            this.setState({
                fromError: true
            });
            submit_enable = false;
        }*/

        if(!this.state.inn || this.state.inn.length < 6){
            this.setState({
                innError: true
            });
            submit_enable = false;
        }

        /*if(!this.state.bank_rs || this.state.bank_rs.length < 2){
            this.setState({
                bank_rsError: true
            });
            submit_enable = false;
        }

        if(!this.state.bank_ks || this.state.bank_ks.length < 2){
            this.setState({
                bank_ksError: true
            });
            submit_enable = false;
        }

        if(!this.state.bank_inn ||  this.state.bank_inn.length < 2){
            this.setState({
                bank_innError: true
            });
            submit_enable = false;
        }

        if(!this.state.bank_bik || this.state.bank_bik.length < 2){
            this.setState({
                bank_bikError: true
            });
            submit_enable = false;
        }

        if(!this.state.bank_kpp || this.state.bank_kpp.length < 2){
            this.setState({
                bank_kppError: true
            });
            submit_enable = false;
        }

        if(!this.state.bank_name || this.state.bank_name.length < 2){
            this.setState({
                bank_nameError: true
            });
            submit_enable = false;
        }*/

        if(!fileInput3.files[0] && !this.state.innFile) {
            this.setState({
                innFileError: true
            });
            submit_enable = false;
        }

        if((!fileInput.files[0] || !fileInput2.files[0]) && (!this.state.passFile1 || !this.state.passFile2)) {
            this.setState({
                passFileError: true
            });
            submit_enable = false;
        }

        if(this.state.rules1 !== true){
            this.setState({
                rules1Error: true
            });
            submit_enable = false;
        }

        if(submit_enable === true){
            this.setState({
                loading: true
            });

            const formData = new FormData();

            formData.append("passport_serial", this.state.passSerial);
            formData.append("passport_number", this.state.passNumb);
            formData.append("passport_from", this.state.from);
            formData.append("passport_address", this.state.address);
            formData.append("inn_number", this.state.inn);
            formData.append("delivery_fio", this.state.delivery_fio);
            formData.append("delivery_phone", this.state.delivery_phone);
            formData.append("date_birth", this.state.date_birth);

            let signDataUrl = this.saveableCanvas.getDataURL();
            let blob = dataURItoBlob(signDataUrl);
            let file = new File( [blob], 'sign.png', { type: 'image/png' } );


            formData.append('sign', file);


            console.log(file);
            //return;

            /*formData.append("bank_rs", this.state.bank_rs);
            formData.append("bank_ks", this.state.bank_ks);
            formData.append("bank_kpp", this.state.bank_kpp);
            formData.append("bank_inn", this.state.bank_inn);
            formData.append("bank_bik", this.state.bank_bik);
            formData.append("bank_name", this.state.bank_name);*/

            if(fileInput.files[0] !== undefined){
                formData.append('passport_photo1', fileInput.files[0]);
            }

            if(fileInput2.files[0] !== undefined){
                formData.append('passport_photo2', fileInput2.files[0]);
            }

            if(fileInput3.files[0] !== undefined){
                formData.append('inn_photo', fileInput3.files[0]);
            }

            await fetch(API_URL + 'updateProfile2', {
                method: "POST",
                body: formData,
                headers:{
                    'X-Auth-Token': localStorage.getItem('auth_key')
                }
            }).then((response) => response.json()).then((response) => {
                if(response.result === true){
                    this.modals.info2Show();

                    this.setState({
                        document_ds: true,
                        inn_ds: true,
                        /*innFile_ds: true,*/
                        address_ds: true,
                        from_ds: true,
                        passNumb_ds: true,
                        passSerial_ds: true,
                        delivery_fio_ds: true,
                        delivery_phone_ds: true,
                        date_birth_ds: true,
                        /*passFile1_ds: true,
                        passFile2_ds: true*/
                    });
                }else{
                    if(response.error.passport_serial) {
                        this.setState({
                            passSerialError: true,
                            passSerialErrorText: response.error.passport_serial[0]
                        });
                    }

                    if(response.error.delivery_phone) {
                        this.setState({
                            delivery_phoneError: true,
                            delivery_phoneErrorText: response.error.delivery_phone[0]
                        });
                    }

                    if(response.error.delivery_fio) {
                        this.setState({
                            delivery_fioError: true,
                            delivery_fioErrorText: response.error.delivery_fio[0]
                        });
                    }

                    if(response.error.date_birth) {
                        this.setState({
                            date_birthError: true,
                            date_birthErrorText: response.error.date_birth[0]
                        });
                    }

                    if(response.error.passport_number) {
                        this.setState({
                            passSerialError: true,
                            passSerialErrorText: response.error.passport_number[0]
                        });
                    }

                    /*if(response.error.passport_from) {
                        this.setState({
                            fromError: true,
                            fromErrorText: response.error.passport_from[0]
                        });
                    }*/

                    if(response.error.passport_address) {
                        this.setState({
                            addressError: true,
                            addressErrorText: response.error.passport_address[0]
                        });
                    }

                    if(response.error.inn_number) {
                        this.setState({
                            innError: true,
                            innErrorText: response.error.inn_number[0]
                        });
                    }
                }

                this.setState({
                    loading: false
                });
            });
        }
    }

    render() {
        if(localStorage.getItem('auth_key')){
            return (
                <>
                    <Header/>
                    <div className="content content--lk">
                        <div className="sc sc__docs">
                            <div className="container">
                                <div className="back__wrap d-flex">
                                    <Link to="/" className="d-flex align-items-center back__link">
                                        <i className="icon__back">
                                            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.146431 3.78072C-0.048831 3.97599 -0.048831 4.29257 0.146431 4.48783L3.32841 7.66981C3.52367 7.86507 3.84026 7.86507 4.03552 7.66981C4.23078 7.47455 4.23078 7.15797 4.03552 6.9627L1.20709 4.13428L4.03552 1.30585C4.23078 1.11059 4.23078 0.794006 4.03552 0.598743C3.84026 0.403481 3.52367 0.403481 3.32841 0.598743L0.146431 3.78072ZM9.60278 3.63428L0.499985 3.63428V4.63428L9.60278 4.63428V3.63428Z" fill="#1E151F"/>
                                            </svg>
                                        </i>
                                        <span>Назад</span>
                                    </Link>
                                </div>
                                <div className="sc__box-title">
                                    Предоставь документы — это важно
                                </div>
                                <div className="sc__box-descr">
                                    В соответствии с п. 1 ст. 224 части 2 налогового <br/>
                                    кодекса РФ, стоимость полученных вами поощрений <br/>
                                    подлежит налогообложению НДФЛ по ставке 13%
                                </div>
                                <div className="lk__box lk__box--docs">
                                    <div className="docs__form docs__form--profile">
                                        <div className="docs__form-title">
                                            Данные участника
                                        </div>
                                        <div className="form__list">
                                            <div className="row">
                                                <div className="col-12 form__col">
                                                    <div className="form__label">
                                                        ФИО
                                                    </div>
                                                    <div className={'form__field ' + ((this.state.delivery_fioError) ? 'form__field--error' : '')}>
                                                        <input
                                                            disabled={this.state.delivery_fio_ds}
                                                            value={this.state.delivery_fio}
                                                            placeholder="ФИО"
                                                            onChange={(delivery_fio)=>{
                                                                this.setState({
                                                                    delivery_fio: delivery_fio.target.value
                                                                });
                                                            }}
                                                            onFocus={()=>{
                                                                this.setState({
                                                                    delivery_fioError: false
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    {this.state.delivery_fioError && (
                                                        <div className="error_message">
                                                            {this.state.delivery_fioErrorText}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-6 form__col">
                                                    <div className="form__label">
                                                        Номер телефона
                                                    </div>
                                                    <div className={'form__field ' + ((this.state.delivery_phoneError) ? 'form__field--error' : '')}>
                                                        <InputMask
                                                            disabled={this.state.delivery_phone_ds}
                                                            value={this.state.delivery_phone}
                                                            type="text"
                                                            mask="+7(999)999-99-99"
                                                            placeholder="Номер телефона"
                                                            autoComplete="nope"
                                                            alwaysShowMask={false}
                                                            onChange={(phone)=>{
                                                                this.setState({
                                                                    delivery_phone: phone.target.value
                                                                });
                                                            }}
                                                            onFocus={()=>{
                                                                this.setState({
                                                                    delivery_phoneError: false
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    {this.state.delivery_phoneError && (
                                                        <div className="error_message">
                                                            {this.state.delivery_phoneErrorText}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-6 form__col">
                                                    <div className="form__label">
                                                        Дата рождения
                                                    </div>
                                                    <div className={'form__field ' + ((this.state.date_birthError) ? 'form__field--error' : '')}>
                                                        <InputMask
                                                            disabled={this.state.date_birth_ds}
                                                            value={this.state.date_birth}
                                                            type="text"
                                                            mask="99.99.9999"
                                                            placeholder="Дата рождения"
                                                            autoComplete="nope"
                                                            alwaysShowMask={false}
                                                            onChange={(date_birth)=>{
                                                                this.setState({
                                                                    date_birth: date_birth.target.value
                                                                });
                                                            }}
                                                            onFocus={()=>{
                                                                this.setState({
                                                                    date_birthError: false
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    {this.state.date_birthError && (
                                                        <div className="error_message">
                                                            {this.state.date_birthErrorText}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="docs__form d-flex">
                                        <div className="docs__form-left">
                                            <div className="docs__form-title">
                                                Паспортные данные
                                            </div>
                                            <div className="form__list">
                                                <div className="row">
                                                    <div className="col-6 form__col">
                                                        <div className="form__label">
                                                            Серия номер
                                                        </div>
                                                        <div className={'form__field ' + ((this.state.passSerialError) ? 'form__field--error' : '')}>
                                                            <InputMask
                                                                disabled={this.state.passSerial_ds}
                                                                value={this.state.passSerial}
                                                                type="text"
                                                                mask="9999 999999"
                                                                placeholder="серия номер"
                                                                autoComplete="nope"
                                                                alwaysShowMask={false}
                                                                onChange={(serial)=>{
                                                                    this.setState({
                                                                        passSerial: serial.target.value
                                                                    });
                                                                }}
                                                                onFocus={()=>{
                                                                    this.setState({
                                                                        passSerialError: false
                                                                    });
                                                                }}
                                                            />

                                                            {this.state.passSerialError && (
                                                                <div className="error_message">
                                                                    {this.state.passSerialErrorText}
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>
                                                    <div className="col-6 form__col">
                                                        <div className="form__label">
                                                            Фото паспорта:
                                                        </div>
                                                        <div className="file__list d-flex">
                                                            <div className="file__it">
                                                                <label>
                                                                    <input type="file"
                                                                           disabled={this.state.passFile1_ds} id="file" onChange={()=>{
                                                                        this.setState({
                                                                            passFileError: false
                                                                        });

                                                                        const fileInput = document.querySelector('#file') ;
                                                                        if (fileInput.files[0]) {
                                                                            this.setState({
                                                                                passFile1: URL.createObjectURL(fileInput.files[0])
                                                                            });
                                                                        }
                                                                    }}/>
                                                                    <div className="file__btn d-flex align-items-center justify-content-center">
                                                                        {this.state.passFile1 && (
                                                                            <img src={this.state.passFile1}/>
                                                                        )}
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="file__it">
                                                                <label>
                                                                    <input type="file"
                                                                           disabled={this.state.passFile2_ds} id="file2" onChange={()=>{
                                                                        this.setState({
                                                                            passFileError: false
                                                                        });
                                                                        const fileInput = document.querySelector('#file2') ;
                                                                        if (fileInput.files[0]) {
                                                                            this.setState({
                                                                                passFile2: URL.createObjectURL(fileInput.files[0])
                                                                            });
                                                                        }
                                                                    }}/>
                                                                    <div className="file__btn d-flex align-items-center justify-content-center">
                                                                        {this.state.passFile2 && (
                                                                            <img src={this.state.passFile2}/>
                                                                        )}
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {this.state.passFileError && (
                                                            <div className="error_message">
                                                                {this.state.passFileErrorText}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/*<div className="col-6 form__col">
                                                        <div className="form__label">
                                                            Кем выдан
                                                        </div>
                                                        <div className={'form__field ' + ((this.state.fromError) ? 'form__field--error' : '')}>
                                                            <textarea
                                                                disabled={this.state.from_ds}
                                                                placeholder="Отделом УФМС..."
                                                                value={this.state.from}
                                                                onChange={(from)=>{
                                                                    this.setState({
                                                                        from: from.target.value
                                                                    });
                                                                }}
                                                                onFocus={()=>{
                                                                    this.setState({
                                                                        fromError: false
                                                                    });
                                                                }}
                                                            />
                                                            {this.state.fromError && (
                                                                <div className="error_message">
                                                                    {this.state.fromErrorText}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>*/}
                                                    <div className="col-6 form__col">
                                                        <div className="form__label">
                                                            Данные регистрации по паспорту
                                                        </div>
                                                        <div className={'form__field ' + ((this.state.addressError) ? 'form__field--error' : '')}>
                                                            <textarea
                                                                disabled={this.state.address_ds}
                                                                placeholder="г. Москва, ул. Зайцева, д. 1, кв. 1"
                                                                value={this.state.address}
                                                                onChange={(address)=>{
                                                                    this.setState({
                                                                        address: address.target.value
                                                                    });
                                                                }}
                                                                onFocus={()=>{
                                                                    this.setState({
                                                                        addressError: false
                                                                    });
                                                                }}
                                                            />
                                                            {this.state.addressError && (
                                                                <div className="error_message">
                                                                    {this.state.addressErrorText}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="docs__form-right">
                                            <div className="docs__form-title">
                                                ИНН
                                            </div>
                                            <div className="form__eq">
                                                <div className="form__label">
                                                    Номер ИНН
                                                </div>
                                                <div className={'form__field ' + ((this.state.innError) ? 'form__field--error' : '')}>
                                                    <input
                                                        disabled={this.state.inn_ds}
                                                        placeholder="1234567890"
                                                        value={this.state.inn}
                                                        onChange={(inn)=>{
                                                            this.setState({
                                                                inn: inn.target.value
                                                            });
                                                        }}
                                                        onFocus={()=>{
                                                            this.setState({
                                                                innError: false
                                                            });
                                                        }}
                                                    />
                                                    {this.state.innError && (
                                                        <div className="error_message">
                                                            {this.state.innErrorText}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form__label">
                                                Фото ИНН:
                                            </div>
                                            <div className="file__list d-flex">
                                                <div className="file__it">
                                                    <label>
                                                        <input
                                                            disabled={this.state.innFile_ds} type="file" id="file3" onChange={()=>{
                                                            this.setState({
                                                                innFileError: false
                                                            });

                                                            const fileInput = document.querySelector('#file3') ;
                                                            if (fileInput.files[0]) {
                                                                this.setState({
                                                                    innFile: URL.createObjectURL(fileInput.files[0])
                                                                });
                                                            }
                                                        }}/>
                                                        <div className="file__btn d-flex align-items-center justify-content-center">
                                                            {this.state.innFile && (
                                                                <img src={this.state.innFile}/>
                                                            )}
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            {this.state.passFileError && (
                                                <div className="error_message">
                                                    {this.state.passFileErrorText}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/*<div className="docs__form docs__form--bank">
                                        <div className="docs__form-title">
                                            Данные банковской карты
                                        </div>
                                        <div className="form__list">
                                            <div className="row">
                                                <div className="col-4 bank-col">
                                                    <div className="form__label">
                                                        р/с
                                                    </div>
                                                    <div className={'form__field ' + ((this.state.bank_rsError) ? 'form__field--error' : '')}>
                                                        <input
                                                            disabled={this.state.bank_rs_ds}
                                                            value={this.state.bank_rs}
                                                            placeholder=""
                                                            onChange={(bank_rs)=>{
                                                                this.setState({
                                                                    bank_rs: bank_rs.target.value
                                                                });
                                                            }}
                                                            onFocus={()=>{
                                                                this.setState({
                                                                    bank_rsError: false
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    {this.state.bank_rsError && (
                                                        <div className="error_message">
                                                            {this.state.bank_rsErrorText}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-4 bank-col">
                                                    <div className="form__label">
                                                        к/с
                                                    </div>
                                                    <div className={'form__field ' + ((this.state.bank_ksError) ? 'form__field--error' : '')}>
                                                        <input
                                                            disabled={this.state.bank_ks_ds}
                                                            value={this.state.bank_ks}
                                                            placeholder=""
                                                            onChange={(bank_ks)=>{
                                                                this.setState({
                                                                    bank_ks: bank_ks.target.value
                                                                });
                                                            }}
                                                            onFocus={()=>{
                                                                this.setState({
                                                                    bank_ksError: false
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    {this.state.bank_ksError && (
                                                        <div className="error_message">
                                                            {this.state.bank_ksErrorText}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-4 bank-col">
                                                    <div className="form__label">
                                                        БИК
                                                    </div>
                                                    <div className={'form__field ' + ((this.state.bank_bikError) ? 'form__field--error' : '')}>
                                                        <input
                                                            disabled={this.state.bank_bik_ds}
                                                            value={this.state.bank_bik}
                                                            placeholder=""
                                                            onChange={(bank_bik)=>{
                                                                this.setState({
                                                                    bank_bik: bank_bik.target.value
                                                                });
                                                            }}
                                                            onFocus={()=>{
                                                                this.setState({
                                                                    bank_bikError: false
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    {this.state.bank_bikError && (
                                                        <div className="error_message">
                                                            {this.state.bank_bikErrorText}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-4 bank-col">
                                                    <div className="form__label">
                                                        ИНН
                                                    </div>
                                                    <div className={'form__field ' + ((this.state.bank_innError) ? 'form__field--error' : '')}>
                                                        <input
                                                            disabled={this.state.bank_inn_ds}
                                                            value={this.state.bank_inn}
                                                            placeholder=""
                                                            onChange={(bank_inn)=>{
                                                                this.setState({
                                                                    bank_inn: bank_inn.target.value
                                                                });
                                                            }}
                                                            onFocus={()=>{
                                                                this.setState({
                                                                    bank_innError: false
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    {this.state.bank_innError && (
                                                        <div className="error_message">
                                                            {this.state.bank_innErrorText}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-4 bank-col">
                                                    <div className="form__label">
                                                        КПП банка
                                                    </div>
                                                    <div className={'form__field ' + ((this.state.bank_kppError) ? 'form__field--error' : '')}>
                                                        <input
                                                            disabled={this.state.bank_kpp_ds}
                                                            value={this.state.bank_kpp}
                                                            placeholder=""
                                                            onChange={(bank_kpp)=>{
                                                                this.setState({
                                                                    bank_kpp: bank_kpp.target.value
                                                                });
                                                            }}
                                                            onFocus={()=>{
                                                                this.setState({
                                                                    bank_kppError: false
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    {this.state.bank_kppError && (
                                                        <div className="error_message">
                                                            {this.state.bank_kppErrorText}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-4 bank-col">
                                                    <div className="form__label">
                                                        Наименование банка
                                                    </div>
                                                    <div className={'form__field ' + ((this.state.bank_nameError) ? 'form__field--error' : '')}>
                                                        <textarea
                                                            disabled={this.state.bank_name_ds}
                                                            value={this.state.bank_name}
                                                            placeholder=""
                                                            onChange={(bank_name)=>{
                                                                this.setState({
                                                                    bank_name: bank_name.target.value
                                                                });
                                                            }}
                                                            onFocus={()=>{
                                                                this.setState({
                                                                    bank_nameError: false
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    {this.state.bank_nameError && (
                                                        <div className="error_message">
                                                            {this.state.bank_nameErrorText}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}
                                    {(!this.state.date_birth_ds || !this.state.delivery_fio_ds || !this.state.delivery_phone_ds) && (
                                      <>
                                          <div className="docs__form docs__form--sign">
                                              <div className="docs__form-title">
                                                  Роспись
                                              </div>
                                              <div className={'sign__box' + (this.state.signError ? ' sign__box--error' : '')}>
                                                  <CanvasDraw
                                                      ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
                                                      canvasHeight={200}
                                                      canvasWidth={400}
                                                      brushRadius={1}
                                                      brushColor={'#1E151F'}
                                                      lazyRadius={1}
                                                      hideInterface={true}
                                                      onChange={()=>{
                                                          this.setState({
                                                              canvasInit: false,
                                                              signError: false,
                                                          })
                                                      }}
                                                  />
                                              </div>
                                              {this.state.signError && (
                                                  <div className="error_message">
                                                      {this.state.signErrorText}
                                                  </div>
                                              )}
                                              {this.saveableCanvas && !this.state.canvasInit && (
                                                  <div className="d-flex">
                                                      <div onClick={()=>{
                                                          if(this.saveableCanvas) {
                                                              this.saveableCanvas.clear();
                                                              this.setState({
                                                                  canvasInit: true
                                                              })
                                                          }
                                                      }} className="btn btn--small d-flex align-items-center justify-content-center">
                                                          Очистить
                                                      </div>
                                                  </div>
                                              )}
                                          </div>
                                          <div className="checkbox__list">
                                              <div className={'checkbox__item ' + ((this.state.rules1Error === true) ? 'has-error' : '')}>
                                                  <div className="checkbox__wrap">
                                                      <label>
                                                          <input type="checkbox" name="agree1" onChange={(agree1)=>{
                                                              this.setState({
                                                                  rules1: agree1.target.checked,
                                                                  rules1Error: false
                                                              });
                                                          }}/>
                                                          <div className="checkbox__decor"/>
                                                          <div className="checkbox__title">
                                                              Согласен на выполнение OOO «Бренднью» функций налогового агента
                                                          </div>
                                                      </label>
                                                  </div>
                                                  {this.state.rules1Error && (
                                                      <div className="error_message">
                                                          {this.state.rules1ErrorText}
                                                      </div>
                                                  )}
                                              </div>
                                          </div>
                                          <div className="d-flex">
                                              <div className={'form__action d-flex justify-content-center ' + ((this.state.loading === true) ? 'disabled' : '')}>
                                                  <div onClick={()=>{
                                                      this.updateProfile()
                                                  }} className="btn d-flex align-items-center justify-content-center">
                                                      Сохранить
                                                  </div>
                                              </div>
                                          </div>
                                      </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modals ref={instance => { this.modals = instance; }} page={'home'} goToScreen={this.goToScreen}/>
                </>

            )
        }else{
            return(
                <Redirect to="/login"/>
            )
        }
    }
}
