import React from 'react'
import { Link, Redirect} from 'react-router-dom'
import {Helmet} from "react-helmet"
import ReactWOW from 'react-wow';
import InputMask from 'react-input-mask'
import {isMobile, isIOS, isAndroid, isTablet} from 'react-device-detect'
import CustomScroll from 'react-custom-scroll';
import Slider from "react-slick"
import Modals from './Modals'

const API_URL = process.env.REACT_APP_API;

let sl_set = {};

let myMap;

export default class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isReady: false,
            email: '',
            emailError: false,
            emailErrorText: 'Введи корректный логин',
            password: '',
            passwordError: false,
            passwordErrorText: 'Введи корректный пароль',
            loading: false
        }

        this.closeModal = this.closeModal.bind(this);
        this.goToScreen = this.goToScreen.bind(this);
    }

    findGetParameter = (parameterName) =>{
        let result = null,
            tmp = [];
        let items = window.location.search.substr(1).split("&");
        for (let index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        }
        return result;
    }

    componentDidMount() {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);

        window.addEventListener('scroll', this.handleScroll);

        let urlParams = window.location.search.substr(1);
        if(urlParams !== '' && urlParams !== undefined && urlParams !== null){
            let utm_source = this.findGetParameter('utm_source');

            if(utm_source !== null && utm_source !== '' && utm_source !== undefined){
                window.location.href = '/game?utm_source=' + utm_source;
            }
        }
    }

    closeModal = () => {
        //this.modals.modalClose()
    }

    goToScreen = (anchor) => {
        this.setState({
            nav: false
        });
        document.body.classList.remove('modal-open');
        document.getElementById(anchor).scrollIntoView({
            behavior: 'smooth'
        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentWillMount(){
        sl_set = {
            infinite: true,
            dots: false,
            arrows: false,
            fade: true,
            waitForAnimate: false,
            /*autoplaySpeed: 3000,
            autoplay: true,*/
            pauseOnHover: false,
            beforeChange: (oldIndex, newIndex) => {
                this.setState({
                    cur_pr: (newIndex + 1),
                    sliding: true
                })
            },
            afterChange: (newIndex) => {
                this.setState({
                    sliding: false
                })
            }
        }
    }

    submitLogin = async() => {
        let submit_enable = true;

        if(this.state.email === ''){
            this.setState({
                emailError: true
            });
            submit_enable = false;
        }else{
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!re.test(this.state.email)){
                this.setState({
                    emailError: true
                });
                submit_enable = false;
            }else{
                this.setState({
                    emailError: false
                });
            }
        }

        if(this.state.password.length < 6){
            this.setState({
                passwordError: true
            });
            submit_enable = false;
        }

        if(submit_enable === true){
            this.setState({
                loading: true
            });

            const formData = new FormData();
            formData.append("login", this.state.email);
            formData.append("password", this.state.password);

            await fetch(API_URL + 'authenticateByEmail', {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
                }
            }).then((response) => response.json()).then((response) => {
                if(response.result === true){
                    localStorage.setItem('auth_key', response.data.auth_key);
                    window.location.href = '/';
                }else{
                    if(response.error.login) {
                        this.setState({
                            emailError: true,
                            emailErrorText: response.error.login[0]
                        });
                    }

                    if(response.error.password) {
                        this.setState({
                            passwordError: true,
                            passwordErrorText: response.error.password[0]
                        });
                    }
                }

                this.setState({
                    loading: false
                });
            });
        }
    }

    render() {
        return (
            <>
                <div className="content">
                    <div className="sc__full sc__login">
                        <div className="container">
                            <div className="login__form">
                                <div className="logo">
                                    <img src={require("../assets/img/logo.svg").default}/>
                                </div>
                                <div className="sc__header text-center">
                                    <div className="sc__subtitle">
                                        Мотивационная программа
                                    </div>
                                    <div className="sc__title">
                                        Клуб кофейных знатоков
                                    </div>
                                </div>
                                <div className="form">
                                    <div className={'form__field ' + ((this.state.emailError) ? 'form__field--error' : '')}>
                                        <input
                                            value={this.state.email}
                                            type="text"
                                            placeholder="Логин"
                                            onChange={(login)=>{
                                                this.setState({
                                                    email: login.target.value
                                                });
                                            }}
                                            onFocus={()=>{
                                                this.setState({
                                                    emailError: false
                                                });
                                            }}
                                        />
                                        {this.state.emailError && (
                                            <div className="error_message">
                                                {this.state.emailErrorText}
                                            </div>
                                        )}
                                    </div>
                                    <div className={'form__field ' + ((this.state.passwordError) ? 'form__field--error' : '')}>
                                        <input
                                            type="password"
                                            value={this.state.password}
                                            placeholder="Пароль"
                                            onChange={(pass)=>{
                                                this.setState({
                                                    password: pass.target.value
                                                });
                                            }}
                                            onFocus={()=>{
                                                this.setState({
                                                    passwordError: false
                                                });
                                            }}
                                        />
                                        {this.state.passwordError && (
                                            <div className="error_message">
                                                {this.state.passwordErrorText}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className={'form__action d-flex justify-content-center ' + ((this.state.loading === true) ? 'disabled' : '')}>
                                        <div onClick={()=>{
                                            this.submitLogin()
                                        }} className="btn d-flex align-items-center justify-content-center">
                                            Авторизоваться
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="d-flex justify-content-center form--after">
                                    <div className="form__action d-flex justify-content-center">
                                        <Link to="/registration" className="btn btn--border d-flex align-items-center justify-content-center">
                                            Зарегистрироваться
                                        </Link>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <Modals ref={instance => { this.modals = instance; }} page={'login'} goToScreen={this.goToScreen}/>
            </>
        )
    }
}
