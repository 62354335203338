import React from 'react'
import {Link, Redirect} from 'react-router-dom'
import {Helmet} from "react-helmet"
import {isMobile, isIOS, isAndroid, isTablet} from 'react-device-detect'
import Modals from './Modals'
import Header from "./Header";

const API_URL = process.env.REACT_APP_API;

let sl_set = {};

export default class Rating extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            rating: []
        }
    }

    componentDidMount() {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);

        if(localStorage.getItem('auth_key')){
            this.getRating()
        }
    }

    getRating = async () => {
        await fetch(API_URL + 'getRating', {
            method: "GET",
            headers:{
                'X-Auth-Token': localStorage.getItem('auth_key'),
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                this.setState({
                    rating: response.data
                });
            }else{
                document.getElementById('logout').click();
            }

            this.setState({
                loading: false
            });

            this.setState({
                isReady: true
            });
        });
    }

    render() {
        if(localStorage.getItem('auth_key')){
            return (
                <>
                    <Header/>
                    <div className="content content--lk">
                        <div className="sc sc__rating">
                            <div className="container">
                                <div className="back__wrap d-flex">
                                    <Link to="/" className="d-flex align-items-center back__link">
                                        <i className="icon__back">
                                            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.146431 3.78072C-0.048831 3.97599 -0.048831 4.29257 0.146431 4.48783L3.32841 7.66981C3.52367 7.86507 3.84026 7.86507 4.03552 7.66981C4.23078 7.47455 4.23078 7.15797 4.03552 6.9627L1.20709 4.13428L4.03552 1.30585C4.23078 1.11059 4.23078 0.794006 4.03552 0.598743C3.84026 0.403481 3.52367 0.403481 3.32841 0.598743L0.146431 3.78072ZM9.60278 3.63428L0.499985 3.63428V4.63428L9.60278 4.63428V3.63428Z" fill="#1E151F"/>
                                            </svg>
                                        </i>
                                        <span>Назад</span>
                                    </Link>
                                </div>
                                <div className="sc__box-title">
                                    Рейтинг кофейных знатоков
                                </div>
                                {/*<div className="sc__box-descr">
                                    Последнее обновление 50 минут назад
                                </div>*/}
                                <div className="rating__box">
                                    <div className="rating__box-marker d-flex justify-content-center align-items-center">
                                        <div className="rating__box-marker-circle"/>
                                        <span>– моя АЗС</span>
                                    </div>
                                    <div className="rating__box-table">
                                        <div className="rating__box-table-scroll">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>
                                                        Место <br/>
                                                        в&nbsp;рейтинге
                                                    </th>
                                                    <th>
                                                        №АЗС
                                                    </th>
                                                    <th>
                                                        НПО
                                                    </th>
                                                    <th>
                                                        Название <br/>
                                                        АЗС
                                                    </th>
                                                    <th>
                                                        Кластер
                                                    </th>
                                                    <th>
                                                        ККМ
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.rating.filter(rt => rt.my).map((rt, i) => (
                                                    <tr className={(rt.my ? 'tr--bolder tr--my' : '')}>
                                                        <td>
                                                            {rt.zIndex}
                                                        </td>
                                                        <td>
                                                            {rt.azs_number}
                                                        </td>
                                                        <td>
                                                            {rt.azs_npo}
                                                        </td>
                                                        <td>
                                                            {rt.azs_name}
                                                        </td>
                                                        <td>
                                                            {rt.azs_claster}
                                                        </td>
                                                        <td>
                                                            {rt.kkm}%
                                                        </td>
                                                    </tr>
                                                ))}
                                                {this.state.rating.filter(rt => !rt.my).map((rt, i) => (
                                                    <tr className={(rt.my ? 'tr--bolder' : '')}>
                                                        <td>
                                                            {rt.zIndex}
                                                        </td>
                                                        <td>
                                                            {rt.azs_number}
                                                        </td>
                                                        <td>
                                                            {rt.azs_npo}
                                                        </td>
                                                        <td>
                                                            {rt.azs_name}
                                                        </td>
                                                        <td>
                                                            {rt.azs_claster}
                                                        </td>
                                                        <td>
                                                            {rt.kkm}%
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modals ref={instance => { this.modals = instance; }} page={'home'} goToScreen={this.goToScreen}/>
                </>

            )
        }else{
            return(
                <Redirect to="/login"/>
            )
        }
    }
}
